<template>
  <div>
    <Toolbar
      :handle-reset="resetForm"
      :handle-submit="onSendInvoiceForm"
      :handle-download="downloadPdf"
      :handle-download-lock="downloadLockDocument"
      :handle-cancel="cancel"
      :handle-correct="correct"
      :download-loading="loadingDownload"
      :download-lock-loading="loadingDownloadLock"
      :enable-submit="!item.frozen"
      :enable-reset="!item.frozen"
      :enable-edit="!item.frozen"
      :enable-download-lock="!item.frozen"
      :enable-delete="!item.frozen"
      :handle-copy="copy"
      :enable-cancel="isInvoice && item.frozen && !item.cancellationInvoice"
      :enable-correct="isInvoice && item.frozen && !item.cancellationInvoice"
    />
    <InvoiceForm
      :errors="violations"
      :values="item"
      ref="updateForm"
      :handle-message="showMessage"
    />
    <Loading :visible="invoiceIsLoading" />
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex';
  import { createHelpers } from 'vuex-map-fields';
  import InvoiceForm from '../../components/invoice/Form';
  import Loading from '../../components/Loading';
  import Toolbar from '../../components/Toolbar';
  import UpdateMixin from '@/mixins/UpdateMixin';
  import DocumentMixin from '@/mixins/DocumentMixin';
  import InvoiceMixin from '@/mixins/InvoiceMixin';

  const servicePrefix = 'Invoice';

  const { mapFields } = createHelpers({
    getterType: 'invoice/getField',
    mutationType: 'invoice/updateField'
  });

  export default {
    name: 'InvoiceUpdate',
    servicePrefix,
    mixins: [UpdateMixin, InvoiceMixin, DocumentMixin],
    components: {
      Loading,
      Toolbar,
      InvoiceForm
    },
    data() {
      return {
        doFreeze: false,
        item: {
          originalUpdateMessage: this.updateMessage,
          positions: []
        }
      };
    },
    computed: {
      ...mapFields(['error', 'isLoading', 'updated', 'violations', 'handled', 'handleAction']),
      ...mapGetters('invoice', ['find']),
      currentDocument() {
        if (!this.item.document) {
          return null;
        }
        let document = this.resolveDocument(this.item.document);
        if (typeof document === 'undefined') {
          return null;
        }
        return document;
      },
      invoiceIsLoading() {
        return this.isLoading || this.loadingDownload || this.loadingDownloadLock;
      },
      isInvoice() {
        return this.item.type === 'invoice';
      }
    },
    methods: {
      ...mapActions('invoice', {
        deleteItem: 'del',
        retrieve: 'load',
        update: 'update',
        actionInvoice: 'action'
      }),
      getItemSubs() {
        return {
          profile: {}
        };
      },
      downloadPdf() {
        if (this.item.frozen) {
          this.getAndDownloadDocument(this.item.document);
          return;
        }
        this.updateMessage = 'Dokument generiert';
        this.generateInvoiceDocument(this.item);
        this.loadingDownload = true;
      },
      downloadLockDocument() {
        this.$confirm(this.$t('Are you sure you want persist and lock? Not reversible!'), {
          title: this.$t('Persist and lock?'),
          color: 'error',
          buttonTrueText: this.$t('Generate & Download'),
          buttonFalseText: this.$t('Cancel')
        }).then((response) => {
          if (!response) {
            return;
          }
          this.doFreeze = true;
          this.generateInvoiceDocument(this.item);
          this.loadingDownloadLock = true;
        });
      },
      copy() {
        this.actionInvoice({ item: this.item, action: 'copy' });
      },
      cancel() {
        this.$confirm(this.$t('Are you sure you want to cancel this invoice?'), {
          buttonTrueText: this.$t('Yes'),
          buttonFalseText: this.$t('No')
        }).then((response) => {
          if (!response) {
            return;
          }
          this.actionInvoice({ item: this.item, action: 'cancel' });
        });
      },
      correct() {
        this.$confirm(this.$t('invoiceEditMessage'), {
          title: this.$t('invoiceEditTitle'),
          icon: null,
          color: '#4a4a49',
          buttonTrueText: this.$t('yesEdit'),
          buttonFalseText: this.$t('No')
        }).then((response) => {
          if (!response) {
            return;
          }
          this.actionInvoice({ item: this.item, action: 'correct' });
        });
      },
      onSendInvoiceForm() {
        if (this.isValidForm()) {
          if (this.$refs.updateForm.$v.item.$model.customer === this.$refs.updateForm.$v.item.$model.participant) {
            delete this.$refs.updateForm.$v.item.$model.customer;
          }
          this.update(this.$refs.updateForm.$v.item.$model);
        }
      },
    },
    watch: {
      currentDocument() {
        if ((this.loadingDownload || this.loadingDownloadLock) && this.currentDocument !== null) {
          this.getAndDownloadDocument(this.item.document).then(() => {
            this.loadingDownload = false;
            this.loadingDownloadLock = false;
            this.updateMessage = this.originalUpdateMessage;
          });
        }
      },
      error() {
        if (this.error === 'Invoice cannot be created for zero total sum') {
          this.loadingDownload = false;
          this.loadingDownloadLock = false;
          this.showError(this.$t('Rechungssumme darf nicht 0,-€ sein'));
        }
      },
      handled() {
        if (this.handleAction === 'copy') {
          this.showMessage(this.$t('InvoiceCopied'));
          this.$router.push({
            name: 'InvoiceUpdate',
            params: { id: this.handled['@id'] }
          });
          this.handleAction = null;
          this.handled = null;
        } else if (this.handleAction === 'cancel') {
          this.showMessage(this.$t('InvoiceCancelled'));
          this.$router.push({
            name: 'InvoiceUpdate',
            params: { id: this.handled['@id'] }
          });
          this.handleAction = null;
          this.handled = null;
        } else if (this.handleAction === 'generate') {
          this.item = { ...this.handled };
          if (this.doFreeze) {
            this.doFreeze = false;
            this.freezeDocument(this.handled).then(() => {
              this.$router.push({
                name: 'InvoiceList'
              });
            });
          }
          this.handleAction = null;
          this.handled = null;
        } else if (this.handleAction === 'correct') {
          this.showMessage(this.$t('InvoiceCorrected'));
          this.$router.push({
            name: 'InvoiceUpdate',
            params: { id: this.handled['@id'] }
          });
          this.handleAction = null;
          this.handled = null;
        }
      }
    }
  };
</script>
